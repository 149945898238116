import {createStore} from "vuex";
import authModule from "@/store/modules/auth"
import mainModule from "@/store/modules/main";
import setupModule from "@/store/modules/tsm_setup";

const store = createStore({
 modules: {
     auth: authModule,
     main: mainModule,
     tsmSetup: setupModule,
 },
});

export default store;
