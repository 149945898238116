const appRoutes = [
    {
        path: "/",
        alias: "/login",
        name: "Login",
        component: () =>
            import(
                /*webpackChunkName: 'component-homepage' */ "@/pages/TsmLoginPage.vue"
                ),
    },
    {
        path: "/logout",
        alias: "/logout",
        name: "Logout",
        component: () =>
            import(
                /*webpackChunkName: 'component-homepage' */ "@/pages/TsmLogoutPage.vue"
                ),
    },
    {
        path: "/contact",
        alias: "/contact",
        name: "Contact",
        component: () =>
            import(
                /*webpackChunkName: 'component-homepage' */ "@/pages/ContactPage.vue"
                ),
    },
    {
        path: "/user-profile",
        alias: "/user-profile",
        name: "UserProfile",
        component: () =>
            import(
                /*webpackChunkName: 'group-tsm' */ "@/pages/UserProfilePage.vue"
                ),
        meta: {
            requiresAuth: true,
        },
    },
];

export default appRoutes;
