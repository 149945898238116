export default function useGenDateTo(startDate, addDays, workdays) {
    //console.log("useGenDateTo")
    //console.log(startDate, addDays, workdays)
    const nextWorkday = (startWorkday) => {
        /*if(workdays[startWorkday]===true){
            return startWorkday;
        }*/
        const addWorkdays=addDays+1;
        let countDays = 0;
        let finalAddDays = 0;
        let weekday = startWorkday;
        for (let i = 0; i < 12; i++) {
            if (weekday === 7) {
                weekday = 0;
            }
            if (workdays[weekday] === true) {
                countDays = countDays + 1;
            }
            if (countDays >= addWorkdays) {
                break;
            }
            weekday = weekday + 1;
            finalAddDays = finalAddDays + 1;
        }
        return finalAddDays
    }
    const genEndDate = () => {
        const datetime = startDate;
        const finalAddDays = nextWorkday(datetime.getDay());
        //console.log(nextWorkday(datetime.getDay()))
        const endDate = new Date();
        const newEndDate = datetime.getDate() + finalAddDays;
        endDate.setDate(newEndDate)

        /*console.log("main_act_reset_main_dates");
        console.log(addDays + "+" + finalAddDays);
        console.log(endDate)*/
        return endDate;
    }
    return genEndDate();
}