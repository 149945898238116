import axios from "axios";
import {reactive, toRef, toRefs} from "vue";


const state = {
    user_id: null,
    user_name: null,
    user_email: null,
    user_su: false,
    user_locations: null,
    staff_id: 0,
    staff_func: null,
    staff_sel_values: null,
    staff_sel_areas: null,
    cross_booking: false,
    location_count: 0,
    sel_location: null,
    sel_customer_id: null,
    sel_user_role: null,
    sel_bo: false,
    sel_staff_value: null,
    sel_staff_area: null,
    main_filter: true,
    main_filter_pf: null,
    access_token: null,
    refresh_token: null,
    token_expires: null,
}
const mutations = {
    setUser(state, payload) {
        state.user_id = payload.user_id;
        state.user_name = payload.user_name;
        state.user_email = payload.user_name;
        state.user_su = payload.user_su;
        state.staff_id = payload.staff_id;
        state.staff_func = payload.staff_func;
        state.staff_sel_values = payload.staff_sel_values;
        state.staff_sel_areas = payload.staff_sel_areas;
        state.cross_booking = payload.cross_booking;
        state.user_locations = payload.user_locations;
        state.location_count = payload.location_count;
        state.sel_location = payload.sel_location;
        state.sel_staff_value = payload.sel_staff_value;
        state.sel_staff_area = payload.sel_staff_area;
        state.sel_customer_id = payload.sel_customer_id;
        state.sel_user_role = payload.sel_user_role;
        state.sel_bo = payload.sel_bo;
        state.main_filter = payload.main_filter;
        state.main_filter_pf = payload.main_filter_pf;
        state.access_token = payload.access_token;
        state.refresh_token = payload.refresh_token;
        state.token_expires = payload.token_expires;
    },
    setLocation(state, payload) {
        state.sel_location = payload.sel_location;
        state.sel_customer_id = payload.sel_customer_id;
        state.sel_user_role = payload.sel_user_role;
        state.sel_bo = payload.sel_bo;
        state.sel_staff_value = null;
        state.sel_staff_area = null;
    },
    resetLocation(state) {
        state.sel_location = null;
        state.sel_customer_id = null;
        state.sel_user_role = null;
        state.sel_bo = false;
        state.sel_staff_value = null;
        state.sel_staff_area = null;
    },
    setMutSelStaffValue(state, payload) {
        state.sel_staff_value = payload.sel_staff_value;
        localStorage.setItem("sel_staff_value", payload.sel_staff_value);
    },
    setMutSelStaffArea(state, payload) {
        state.sel_staff_area = payload.sel_staff_area;
    }
}
const actions = {
    auth(context, payload) {
        let url = "";
        if (payload.mode === "signin") {
            url = "/token";
        } else if (payload.mode === "signup") {
            url = "/signup"
        } else {
            return;
        }
        const authDO = {
            username: payload.email,
            password: payload.password,
            returnSecureToken: true,
        }
        return axios
            .post(url,
                authDO, {})
            .then((response) => {
                //console.log(response);
                const expiresIn = Number(response.data.token_expires);
                const expDate = new Date().getTime() + expiresIn;
                let sel_location = null;
                let sel_customer_id = null;
                let sel_user_role = null;
                let sel_bo = false;
                let sel_staff_value = null;
                let sel_staff_area = null;
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("refresh_token", response.data.refresh_token);
                localStorage.setItem("token_expires", expDate);
                localStorage.setItem("user_id", response.data.user_id);
                localStorage.setItem("user_name", response.data.user_name);
                localStorage.setItem("user_su", response.data.user_su);
                localStorage.setItem("staff_id", response.data.staff_id);
                localStorage.setItem("staff_func", response.data.staff_func);
                localStorage.setItem("staff_sel_values", response.data.staff_sel_values);
                localStorage.setItem("user_locations", response.data.user_locations);
                localStorage.setItem("sel_location", sel_location);
                localStorage.setItem("sel_customer_id", sel_customer_id);
                localStorage.setItem("sel_user_role", sel_user_role);
                localStorage.setItem("sel_staff_value", sel_staff_value);
                localStorage.setItem("sel_bo", sel_bo);

                context.commit("setUser", {
                    user_id: response.data.user_id,
                    user_name: response.data.user_name,
                    user_email: response.data.user_name,
                    user_su: response.data.user_su,
                    staff_id: response.data.staff_id,
                    staff_func: response.data.staff_func,
                    staff_sel_values: response.data.staff_sel_values,
                    staff_sel_areas: response.data.staff_sel_areas,
                    cross_booking: response.data.cross_booking,
                    user_locations: response.data.user_locations,
                    location_count: response.data.location_count,
                    sel_location: sel_location,
                    sel_customer_id: sel_customer_id,
                    sel_user_role: sel_user_role,
                    sel_staff_area: sel_staff_area,
                    sel_staff_value: sel_staff_value,
                    sel_bo: sel_bo,
                    main_filter: response.data.main_filter,
                    main_filter_pf: response.data.main_filter_pf,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    token_expires: expDate
                })
                if (!isNaN(response.data.main_filter_pf) && response.data.main_filter_pf > 0) {
                    context.commit("setMainMutFilterLoadingStatus", {main_filter_loading_status: response.data.main_filter_pf})
                } else {
                    context.commit("setMainMutFilterLoadingStatus", {main_filter_loading_status: 1})
                }
                return true;
            })
            .catch((error) => {
                //console.log(error.response.data.error.message)
                const errorMessage = new Error(
                    error.response.data.error.message || "UNKNOWN_ERROR");
                console.log(errorMessage);
                throw errorMessage;
            });
    },
    signup(context, payload) {
        const signupDO = {
            ...payload,
            mode: "signup",
            returnSecureToken: true,
        };
        return context.dispatch("auth", signupDO);
    },
    signin(context, payload) {
        const signinDO = {
            ...payload,
            mode: "signin",
            returnSecureToken: true,
        };
        return context.dispatch("auth", signinDO);
    },
    signout(context) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_su");
        localStorage.removeItem("staff_id");
        localStorage.removeItem("staff_func");
        localStorage.removeItem("staff_sel_values");
        localStorage.removeItem("sel_staff_value");
        localStorage.removeItem("user_locations");
        localStorage.removeItem("sel_location");
        localStorage.removeItem("sel_customer_id");
        localStorage.removeItem("sel_user_role");
        localStorage.removeItem("sel_bo");
        localStorage.removeItem("expires_in");
        context.commit("setUser", {
            user_id: null,
            user_name: null,
            user_email: null,
            user_su: false,
            staff_id: 0,
            staff_func: null,
            staff_sel_values: null,
            staff_sel_areas: null,
            cross_booking: false,
            user_locations: null,
            location_count: 0,
            sel_location: null,
            sel_customer_id: null,
            sel_user_role: null,
            sel_bo: false,
            sel_staff_value: null,
            sel_staff_area: null,
            access_token: null,
            refresh_token: null,
            token_expires: 0,
            main_filter: true,
            mein_filter_pf: 1,
        });

    },
    changeLocation(context, payload) {
        context.commit("setLocation", {
            sel_location: payload.sel_location,
            sel_customer_id: payload.sel_customer_id,
            sel_user_role: payload.sel_user_role,
            sel_bo: payload.sel_bo,
        });
        return true;
    },
    resetLocation(context) {
        context.commit("resetLocation");
    },
    actAuthSelStaffValue(context, payload) {
        context.commit("setMutSelStaffValue", {sel_staff_value: payload.sel_staff_value})
    },
    actAuthSelStaffArea(context, payload) {
        context.commit("setMutSelStaffArea", {sel_staff_area: payload.sel_staff_area})
    }
}
const getters = {
    auth_get_location_count: (state) => state.location_count,
    auth_get_user_name: (state) => state.user_name,
    auth_get_user_email: (state) => state.user_email,
    auth_get_stuff_func: (state) => state.staff_func,
    auth_get_has_staff_func: (state) => !!state.staff_func,
    auth_get_is_yard_user: (state) => {
        if (!state.staff_func) {
            return true
        } else if (state.staff_func === 'by1') {
            return true;
        } else {
            return false;
        }
    },
    isAuthMainFilterVisible: (state) => state.main_filter,
    getAuthMainFilterPf: (state) => state.main_filter_pf,
    getStaffId: (state) => state.staff_id,
    getStaffFunc: (state) => state.staff_func,
    getStaffSelValue: (state) => state.sel_staff_value,
    getStaffSelArea: (state) => state.sel_staff_area,
    auth_get_staff_sel_area: (state) => state.sel_staff_area,
    getStaffSelValues: (state) => state.staff_sel_values,
    getStaffSelAreas: (state) => state.staff_sel_areas,
    isStaffSelValueSelected: (state) => !!state.sel_staff_value,
    isStaffSelAreaSelected: (state) => !!state.sel_staff_area,
    getUserId: (state) => state.user_id,
    auth_get_is_logged_in: (state) => {
        if (typeof state.user_id === 'undefined' || state.user_id === null || isNaN(state.user_id)) {
            return false;
        }
        return state.user_id > 0;
    },
    getUserLocations: (state) => state.user_locations,
    isAuthenticated: (state) => !!state.access_token,
    isLocationSelected: (state) => !!state.sel_location,
    auth_get_is_ob_user: (state) => state.sel_bo,
    auth_get_is_super_user: (state) => state.user_su,
    getSelectedLocation: (state) => {
        if (typeof state.sel_location === 'undefined') {
            return null;
        }
        return state.sel_location;
    },
    auth_get_cross_booking: (state) => state.cross_booking,
    getSelectedCustomerId: (state) => state.sel_customer_id,
    getSelectedUserRole: (state) => state.sel_user_role,
    auth_token: (state) => state.access_token,
    auth_get_token_expires_token: (state) => state.access_token,
    auth_get_access_token: (state) => state.access_token,
    auth_get_refresh_token: (state) => state.refresh_token,
    getLocationCount(state) {
        let counter = 0;
        for (const global_location in state.user_locations) {
            for (const customer_id in state.user_locations[global_location]) {
                if (customer_id > 0) {
                    counter++
                }
            }
        }
        return counter;
    },
    getStaffSelValuesCount(state) {
        if (state.staff_sel_values === null || Array.isArray(state.staff_sel_values) === false) {
            return 0;
        }
        if (state.staff_sel_values.length === 1) {
            state.sel_staff_value = state.staff_sel_values[0];
        }
        return state.staff_sel_values.length
    },
    getStaffSelAreasCount(state) {
        if (state.staff_sel_areas === null || Array.isArray(state.staff_sel_areas) === false) {
            return 0;
        }
        //console.log(state.staff_sel_areas[0])
        if (state.staff_sel_areas.length === 1) {
            state.sel_staff_area = state.staff_sel_areas[0];
        }
        return state.staff_sel_areas.length
    },
    auth_get_customer_name(state) {
        return state.sel
    },
    auth_get_customer_number(state) {
        return state.user_locations[state.sel_location][state.sel_customer_id].kdnr;
    },
    auth_get_first_location(state) {
        const tmpLoc = state.user_locations;
        const val_1 = tmpLoc[Object.keys(tmpLoc)[0]];
        const val_2 = val_1[Object.keys(val_1)[0]];
        //console.log(val_2.location)
        return val_2;
    },
    auth_get_role_change_tsm_date(state) {
        if (state.staff_func === 'by1') {
            return false;
        }
        return true;
    },
    auth_get_role_change_location(state) {
        if (state.staff_func === 'by1') {
            return false;
        }
        if (state.getLocationCount > 1) {
            return false
        }
        return true;
    }
}


const authModule = {
    state,
    mutations,
    actions,
    getters,
}

export default authModule
