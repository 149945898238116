import axios from "axios";


const state = {
    tsm_setup_refresh_interval: 20,
    tsm_setup_refresh_active: false,
    loading_states: null,
    order_pattern: null,
    timeline_partitioning: 30,
    client_setup: {
        client: "single",
        loading_states: "11100001",
        order_pattern: "",
        workdays:[false,true,true,true,true,true,false]
    },
}
const actions = {
    act_tsm_setup_refresh_active(context, payload) {
        const setupDO = {
            ...payload
        };
        context.commit("setTsmSetupMutRefreshActive", setupDO)
    },
    tsm_setup(context, payload) {
        // console.log(payload);
        const access_token = context.rootGetters.auth_get_access_token;
        const url = "/vm/setup/" + payload.sel_location;

        return axios
            .get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + access_token
                    },
                })
            .then((response) => {
                // console.log('tsm_setup -> Antwort');
                // console.log(response);
                // console.log(response.data.data.tsm_setup.loadingstate);
                context.commit("setSetup", {
                    loading_state: response.data.data.tsm_setup.loadingstate,
                    order_pattern: response.data.data.tsm_setup.orderspattern,
                    timeline_partitioning: response.data.data.tsm_setup.timeline_matrix_partitioning,
                })

            })
            .catch((error) => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                const errorMessage = new Error(
                    error.response.data.error.message || "UNKNOWN_ERROR");
                throw errorMessage;
            });
    },
    load_setup(context, payload) {
        const setupDO = {
            ...payload,
            mode: "load_setup",
        };
        return context.dispatch("tsm_setup", setupDO);
    },
}

const mutations = {
    setSetup(state, payload) {
        // console.log(payload);
        state.loading_state = payload.loading_state;
        state.order_pattern = payload.order_pattern;
        state.timeline_partitioning = payload.timeline_partitioning;
    },
    setTsmSetupMutRefreshActive(state, payload) {
        state.tsm_setup_refresh_active = payload.tsm_setup_refresh_active
    }
}
const getters = {
    getTsmSetupLoadingState: (state) => state.loading_state,
    getTsmSetupOrderPattern: (state) => state.order_pattern,
    getTsmSetupTimelinePartitioning: (state) => state.timeline_partitioning,
    getTsmSetupRefreshInterval: (state) => state.tsm_setup_refresh_interval * 1000,
    getTsmSetupRefreshActive: (state) => state.tsm_setup_refresh_active,
    getTsmSetupWorkdays:(state) => state.client_setup.workdays,
}


const setupModule = {
    state,
    mutations,
    actions,
    getters,
}

export default setupModule