import axios from "axios";
import useGenDateTo from "@/composables/useGenDateTo";
import useTsmDateTimeString from "@/composables/TsmDateTimeString";

const state = {
    main_items: null,
    main_item: null,
    main_item_id: 0,
    main_item_chg_loading_status: false,
    main_item_chg_item: false,
    main_vm: null,
    main_enqueue_items: null,
    main_date_from: null,
    main_date_to: null,
    main_add_days: 0,
    main_is_today: null,
    main_force_reload: true,
    main_refreshed: false,
    main_filter_loading_status: 1,
    main_filter_sort: 1,
    main_filter_loading_area: 1,
    main_filter_loading_type: 1,
    main_part_2: {
        v1: [7, 107, 207, 507, 529],
        v2: [8, 108, 208],
        v3: [9, 109, 209, 509, 531, 508, 530],
    },
}
const mutations = {
    setMainMutItem(state, payload) {
        state.main_item_id = payload.main_item_id;
        state.main_item = payload.main_item;
    },
    //setMainMutForceReload(state, payload) {
    //    state.main_force_reload = payload.status;
    //},
    main_mut_set_force_reload(state, payload) {
        state.main_force_reload = payload.main_force_reload;
    },
    main_mut_set_is_today(state, payload) {
        state.main_is_today = payload.main_is_today;
    },
    setMainMutRefreshed(state, payload) {
        state.main_refreshed = payload.status;
    },
    setMainItems(state, payload) {
        state.main_items = payload.main_items;
    },
    setMainMutValues(state, payload) {
        state.main_date_from = payload.main_date_from;
        state.main_date_to = payload.main_date_to;
    },
    resetMainMutItems(state) {
        state.main_items = null;
    },
    resetMainMutItem(state) {
        state.main_item_id = 0;
        state.main_item = null;
    },
    resetMainMut(state) {
        const datetime = new Date();
        state.main_items = null;
        state.main_item_id = 0;
        state.main_vm = null;
        state.main_enqueue_items = null;
        state.main_refreshed = false;
        state.main_date_from = datetime;
        state.main_date_to = datetime;
        state.main_add_days = 0;
        state.force_reload = true;
    },
    resetMainMutFilter(state) {
        //console.log("resetMainMutFilter")
        //console.log(state)
        state.main_filter_loading_status = 1;
        state.main_filter_sort = 1;
        state.main_filter_loading_area = 1;
        state.main_filter_loading_type = 1;
    },
    setMainMutFilterLoadingStatus(state, payload) {
        state.main_filter_loading_status = payload.main_filter_loading_status;
    },
    setMainMutFilterSort(state, payload) {
        state.main_filter_sort = payload.main_filter_sort;
    },
    setMainMutFilterLoadingArea(state, payload) {
        state.main_filter_loading_area = payload.main_filter_loading_area;
    },
    setMainMutFilterLoadingType(state, payload) {
        state.main_filter_loading_type = payload.main_filter_loading_type;
    },
    setEnqueueItem(state, payload) {
        state.main_enqueue_items.push(payload.enqueue_item);
    },
    main_mut_set_chg_loading_status(state, payload) {
        state.main_item_chg_loading_status = payload.main_item_chg_loading_status;
    },
    main_mut_set_chg_main_item(state, payload) {
        state.main_item_chg_item = payload.main_item_chg_item;
    },
    main_mut_set_loading_checked(state, payload) {
        state.main_item.confirm_2 = payload.loading_checked;
    },
    main_mut_reset_main_dates(state, payload) {
        state.main_date_from = payload.main_date_from;
        state.main_date_to = payload.main_date_to;
    },
    main_mut_set_main_add_days(state, payload) {
        state.main_add_days = payload.main_add_days;
    },
    main_mut_set_main_date_to(state, payload) {
        state.main_date_to = payload.main_date_to;
    },
}
const actions = {
    main_act_set_is_today(context, payload) {
        const main_do = {
            "main_is_today": payload.main_is_today
        };
        context.commit("main_mut_set_is_today", main_do)
    },
    main_act_set_add_days(context, payload) {
        const main_do = {
            "main_add_days": payload.main_add_days
        };
        context.commit("main_mut_set_main_add_days", {"main_add_days": payload.main_add_days})
    },
    main_act_reset_main_dates(context) {
        const datetime = new Date();
        const newStartDate = useGenDateTo(datetime, 0, context.rootGetters.getTsmSetupWorkdays)
        const newEndDate = useGenDateTo(newStartDate, context.getters.main_get_add_days, context.rootGetters.getTsmSetupWorkdays)
        //console.log("main_act_reset_main_dates newEndDate")
        //console.log(newStartDate)
        //console.log(newEndDate)
        //const endDate = new Date();
        //const addDays = context.getters.main_get_add_days;
        //console.log("main_act_reset_main_dates");
        //console.log(addDays)
        //console.log(context.rootGetters.getTsmSetupWorkdays)
        //const newEndDate = datetime.getDate() + addDays
        //endDate.setDate(newEndDate)
        //console.log(datetime)
        //console.log(endDate)
        const main_dates_do = {
            "main_date_from": newStartDate,
            "main_date_to": newEndDate
        }
        context.commit("main_mut_reset_main_dates", main_dates_do)
    },
    main(context, payload) {
        //console.log(payload)
        const access_token = context.rootGetters.auth_get_access_token;
        const url = '/timeslots';
        //url = url + '?auth=' + token;
        const mainDO = {
            "location": payload.location,
            "customer_id": payload.customer_id,
            "user_id": payload.user_id,
            "data_scope": payload.data_scope,
            "date_from": payload.tsm_date_from,
            "date_to": payload.tsm_date_to
        }
        return axios
            .post(url, mainDO, {
                headers: {
                    'Authorization': 'Bearer ' + access_token
                },
            })
            .then((response) => {
                context.commit("setMainItems", {
                    main_items: response.data
                })

                //console.log(response);
                context.commit("setMainMutRefreshed", {"status": true})
                //context.commit("setMainMutForceReload", {"status": false})
                context.commit("main_mut_set_force_reload", {"main_force_reload": false})
                return true;
            })
            .catch(() => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                //const errorMessage = new Error(
                //    error.response.data.error.message || "UNKNOWN_ERROR");
                //throw errorMessage;
                return false;
            });
    },
    mainActItem(context, payload) {
        const access_token = context.rootGetters.auth_get_access_token;
        const url = "/timeslot/" + payload.location + "/" + payload.res_id;
        return axios
            .get(url, {
                headers: {
                    'Authorization': 'Bearer ' + access_token
                },
            })
            .then((response) => {
                //console.log(response);
                context.commit("setMainMutItem", {
                    main_item: response.data.data,
                    main_item_id: response.data.data.resid,
                })
                context.commit("main_mut_set_chg_loading_status", {main_item_chg_loading_status: true})
                context.commit("main_mut_set_chg_main_item", {main_item_chg_item: true})
                context.commit("setMainMutRefreshed", {"status": false})
                // context.commit("setMainMutForceReload", {"status": false})
                context.commit("main_mut_set_force_reload", {"main_force_reload": false})
                return true;
            })
            .catch(() => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                //const errorMessage = new Error(
                //    error.response.data.error.message || "UNKNOWN_ERROR");
                //throw errorMessage;
                return false;
            });
    },
    main_act_set_chg_loading_status(context, payload) {
        let set_value = payload.main_item_chg_loading_status;
        //set_value = true;
        context.commit("main_mut_set_chg_loading_status", {main_item_chg_loading_status: set_value})
    },
    main_act_set_chg_main_item(context, payload) {
        let set_value = payload.main_item_chg_item;
        //set_value = true;
        context.commit("main_mut_set_chg_main_item", {main_item_chg_item: set_value})
    },
    main_act_reset_main_item(context) {
        const mainDO = {
            main_item_id: 0,
            main_item: null,
            //main_item_chg_loading_status: false,
            //main_item_chg_item: false,
        };
        context.commit("setMainMutItem", mainDO);
    },
    setMainActValues(context, payload) {
        //console.log("setMainActValues")
        //console.log(context.getters.main_get_add_days)
        const datetime = payload.main_date_from;
        const newStartDate = useGenDateTo(datetime, 0, context.rootGetters.getTsmSetupWorkdays)
        const newEndDate = useGenDateTo(newStartDate, context.getters.main_get_add_days, context.rootGetters.getTsmSetupWorkdays)

        //const startDate = payload.main_date_from;
        //const endDate = new Date();
        //const addDays = context.getters.main_get_add_days;
        //console.log("main_act_reset_main_dates");
        //console.log(addDays)
        //const newEndDate = startDate.getDate() + addDays
        //endDate.setDate(newEndDate)
        const mainDO = {
            main_date_from: newStartDate,
            main_date_to: newEndDate,
        };
        return context.commit("setMainMutValues", mainDO);
    },
    setMainActItem(context, payload) {
        const mainDO = {
            main_item_id: payload.main_item_id,
            main_item: payload.main_item,
            //main_item_chg_loading_status: payload.main_item_chg_loading_status,
            //main_item_chg_item: payload.main_item_chg_item,
        };
        return context.commit("setMainMutItem", mainDO);
    },
    resetMainActItem(context) {
        const mainDO = {
            main_item_id: 0,
            main_item: null,
            //main_item_chg_loading_status: false,
            //main_item_chg_item: false,
        };
        return context.commit("setMainMutItem", mainDO);
    },
    setMainActItemsRefreshed(context, payload) {
        return context.commit("setMainMutRefreshed", {"main_refreshed": payload.status})
    },
    getMainTimeslots(context, payload) {
        const mainDO = {
            ...payload,
            mode: "get_timeslots",
            returnSecureToken: true,
        };
        return context.dispatch("main", mainDO);
    },
    getMainActTimeslot(context, payload) {
        const mainDO = {
            ...payload,
            mode: "get_timeslot",
            returnSecureToken: true,
        };
        return context.dispatch("mainActItem", mainDO);
    },
    setEnqueueForTimeslot(context, payload) {
        const enqueueDO = {
            ...payload,
            action: "lock",
            data_scope: "single"
        };
        return context.dispatch("enqueue", enqueueDO);
    },
    delEnqueueForTimeslot(context, payload) {
        const enqueueDO = {
            ...payload,
            action: "unlock",
            data_scope: "single"
        };
        return context.dispatch("enqueue", enqueueDO);
    },
    enqueue(context, payload) {
        //console.log(payload);
        const access_token = context.rootGetters.auth_get_access_token;
        const url = "/sys/enqueue/item";
        const enqueueDO = {
            "res_id": payload.res_id,
            "user_id": payload.user_id,
            "staff_id": context.getters.getStaffId,
            "staff_area": payload.staff_area,
            "staff_value": payload.staff_value,
            "action": payload.action,
            "data_scope": payload.data_scope,
        }
        return axios
            .post(url,
                enqueueDO, {
                    headers: {
                        'Authorization': 'Bearer ' + access_token
                    },
                })
            .then(() => {
                context.commit("setMainMutRefreshed", {"status": false});
                //context.commit("setMainMutForceReload", {"status": true});
                context.commit("main_mut_set_force_reload", {"main_force_reload": true});
                return true;
            })
            .catch((error) => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                console.log('Store->main->action->enqueue->ERROR');
                console.log(error);
                return false;
            });
    },
    actMainSetLoadingStatus(context, payload) {
        const loadingStatusDO = {
            ...payload,
            user_id: context.getters.getUserId,
            staff_id: context.getters.getStaffId,
            vehicle: context.getters.getStaffSelValue,
            api_action: "set",
            pf_mode: "auto",
            logging: true
        };
        return context.dispatch("main_act_api_set_loading_status", loadingStatusDO);
    },
    actMainResetLoadingStatus(context, payload) {
        const loadingStatusDO = {
            ...payload,
            user_id: context.getters.getUserId,
            staff_id: context.getters.getStaffId,
            vehicle: context.getters.getStaffSelValue,
            api_action: "reset",
            pf_mode: "auto",
            logging: true
        };
        return context.dispatch("main_act_api_set_loading_status", loadingStatusDO);
    },
    main_act_set_loading_checked_by_api(context, payload) {
        const loadingStatusDO = {
            ...payload,
            user_id: context.getters.getUserId,
            staff_id: context.getters.getStaffId,
            vehicle: context.getters.getStaffSelValue,
            logging: true
        };

        return context.dispatch("main_act_api_loading_checked", loadingStatusDO);
    },
    main_act_set_loading_checked(context, payload) {
        const loadingCheckedDO = {
            ...payload,
        };
        context.commit("main_mut_set_loading_checked", loadingCheckedDO)
    },

    main_act_api_set_loading_status(context, payload) {
        //console.log("store->main->index->actMainCallApiLoadingStatus");
        //console.log(payload);
        const access_token = context.rootGetters.auth_get_access_token;
        let url = "/timeslot/loading/set";
        if (payload.api_action === "reset") {
            url = "/timeslot/loading/reset";
        }
        if (payload.api_action === "load_checked") {
            url = "/timeslot/loading/set/checked";
        }
        const loadingDO = {
            "res_id": payload.res_id,
            "user_id": payload.user_id,
            "staff_id": payload.staff_id,
            "location": payload.location,
            "vehicle": payload.vehicle,
            "logging": payload.logging,
            "pf_mode": payload.pf_mode,
            "pf_config": context.getters.getTsmSetupLoadingState,
            "pf_status": payload.pf_status,
            "pf_status_set": payload.pf_status_set,
            "pf_value": payload.pf_value,
            "pf_values": payload.pf_values,
            "loading_checked": payload.loading_checked,
            "tsm_timestamp": payload.tsm_timestamp
        }
        return axios
            .put(url,
                loadingDO, {
                    headers: {
                        'Authorization': 'Bearer ' + access_token
                    },
                })
            .then((response) => {
                //console.log(response);
                if (context.getters.getMainItemId > 0) {
                    context.commit("setMainMutItem", {
                        main_item: response.data.data,
                        main_item_id: response.data.data.resid
                    })
                }
                context.commit("setMainMutRefreshed", {"status": false});
                //context.commit("setMainMutForceReload", {"status": true});
                context.commit("main_mut_set_force_reload", {"main_force_reload": true})
                return true;
            })
            .catch((error) => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                console.log('Store->main->action->->ERROR');
                console.log(error);
                //context.commit("setMainMutForceReload", {"status": false});
                context.commit("main_mut_set_force_reload", {"main_force_reload": false})
                return false;
            });
    },


    main_act_api_loading_checked(context, payload) {
        //console.log("store->main->index->actMainCallApiLoadingStatus");
        //console.log(payload);
        const access_token = context.rootGetters.auth_get_access_token;
        const url = "/timeslot/loading/set/checked";
        const loadingDO = {
            "res_id": payload.res_id,
            "user_id": payload.user_id,
            "staff_id": payload.staff_id,
            "location": payload.location,
            "vehicle": payload.vehicle,
            "logging": payload.logging,
            "pf_status": payload.pf_status,
            "loading_checked": payload.loading_checked,
        }
        return axios
            .put(url,
                loadingDO, {
                    headers: {
                        'Authorization': 'Bearer ' + access_token
                    },
                })
            .then((response) => {
                if (context.getters.getMainItemId > 0) {
                    context.commit("setMainMutItem", {
                        main_item: response.data.data,
                        main_item_id: response.data.data.resid
                    })
                }
                context.commit("setMainMutRefreshed", {"status": false});
                //context.commit("setMainMutForceReload", {"status": true});
                context.commit("main_mut_set_force_reload", {"main_force_reload": true})
                return true;
            })
            .catch((error) => {
                // console.log('API-FEHLER: ' + error.response.data.error.message)
                console.log('Store->main->action->->ERROR');
                console.log(error);
                //context.commit("setMainMutForceReload", {"status": false});
                context.commit("main_mut_set_force_reload", {"main_force_reload": false})
                return false;
            });
    },
    resetMainActItems(context) {
        context.commit("resetMainMutItems");
    },
    resetMainAct(context) {
        context.commit("resetMainMut");
    },
    setMainActForceReload(context, payload) {
        context.commit("setMainMutForceReload", {"status": payload.status});
    },
    resetMainActAllFilter(context) {
        context.commit("resetMainMutFilter")
    },
    setMainActFilterLoadingStatus(context, payload) {
        context.commit("setMainMutFilterLoadingStatus", {"main_filter_loading_status": payload.main_filter_loading_status})
    },
    setMainActFilterSort(context, payload) {
        context.commit("setMainMutFilterSort", {"main_filter_sort": payload.main_filter_sort})
    },
    setMainActFilterLoadingArea(context, payload) {
        context.commit("setMainMutFilterLoadingArea", {"main_filter_loading_area": payload.main_filter_loading_area})
    },
    setMainActFilterLoadingType(context, payload) {
        context.commit("setMainMutFilterLoadingType", {"main_filter_loading_type": payload.main_filter_loading_type})
    },
    main_act_set_force_reload(context, payload) {
        //context.commit("setMainMutForceReload", {"main_force_reload": payload.main_force_reload})
        context.commit("main_mut_set_force_reload", {"main_force_reload": payload.main_force_reload})
    },
    main_act_api_search_single(context, payload) {
        const access_token = context.rootGetters.auth_get_access_token;
        const url = "/self-registration";
        //console.log(payload)
        const searchDo = {
            ...payload
        }
        context.commit("resetMainMutItem")
        return axios
            .post(url, searchDo, {
                headers: {
                    'Authorization': 'Bearer ' + access_token
                },
            })
            .then((response) => {
                //console.log(response);
                context.commit("setMainMutItem", {
                    main_item: response.data.data,
                    main_item_id: response.data.data.resid,
                })
                context.commit("main_mut_set_chg_loading_status", {main_item_chg_loading_status: true})
                context.commit("main_mut_set_chg_main_item", {main_item_chg_item: true})
                context.commit("setMainMutRefreshed", {"status": false})
                // context.commit("setMainMutForceReload", {"status": false})
                context.commit("main_mut_set_force_reload", {"main_force_reload": false})
                return true;
            })
            .catch((error) => {
                console.log('API-FEHLER: ' + error.response.data.error.message)
                const errorMessage = new Error(
                    error.response.data.error.message || "UNKNOWN_ERROR");
                throw errorMessage;

            });
    },
}
const getters = {
    getMainTimeslots: (state) => {
        if (state.main_items !== null) {
            return state.main_items;
        }
        return null;
    },
    getMainTimeslot: (state) => {
        if (state.main_item !== null) {
            return state.main_item;
        }
        return null;
    },
    getMainForeignTimeslots: (state) => {
        if (state.main_items !== null) {
            return state.main_items.data;
        }
        return null;
    },
    mainGetTimeslotCount: (state) => {
        const items = state.main_items;
        let countTotal = 0;
        let countOpen = 0;
        let countRegistered = 0;
        let countLoading = 0;
        let countFinished = 0;
        let countExit = 0;
        //console.log(state.main_date_from)
        //console.log(useTsmDateTimeString(state.main_date_from, "tsm-date", "de"))
        const tsmDate = useTsmDateTimeString(state.main_date_from, "tsm-date", "de");
        //console.log(items);
        if (items !== null) {
            items.data.forEach((timeslot, index) => {
                countTotal += 1;
                if (timeslot.loading_status === 0 && timeslot.datum === tsmDate) {
                    countOpen += 1;
                } else if (timeslot.loading_status === 7) {
                    countExit += 1;
                } else if (timeslot.loading_status === 1) {
                    countRegistered += 1;
                } else if (timeslot.loading_status > 1 && timeslot.loading_status < 6) {
                    countLoading += 1;
                } else if (timeslot.loading_status === 6) {
                    countFinished += 1;
                }

            });
        }
        const countTimeslots = {
            //count_total: countTotal,
            count_open: countOpen,
            count_registered: countRegistered,
            count_loading: countLoading,
            count_finished: countFinished,
            count_exit: countExit
        }
        return countTimeslots;
    },
    mainGetTimeslotsOpen: (state) => {
        let data = []
        //const items = state.main_items
        if (state.main_items === null) {
            return data
        } else {
            const items = state.main_items.data;
            //console.log(items.length)
            items.forEach((timeslot, index) => {
                if (timeslot.loading_status === 0 && timeslot.resid === timeslot.timeslotpartgroupid) {
                    data.push(timeslot);
                }
            });
        }
        return data;
    },
    mainGetTimeslotsRegistered: (state) => {
        const items = state.main_items;
        let data = []
        if (items === null) {
            return data
        } else {
            items.data.forEach((timeslot, index) => {
                if (timeslot.loading_status === 1) {
                    data.push(timeslot);
                }
            });
        }
        return data;
    },
    mainGetTimeslotsLoading: (state) => {
        const items = state.main_items;
        let data = []
        if (items === null) {
            return data
        } else {
            items.data.forEach((timeslot, index) => {
                if (timeslot.loading_status > 1 && timeslot.loading_status < 6) {
                    data.push(timeslot);
                }
            });
        }
        return data;
    },
    mainGetTimeslotsFinished: (state) => {
        const items = state.main_items;
        let data = []
        if (items === null) {
            return data
        } else {
            items.data.forEach((timeslot, index) => {
                if (timeslot.loading_status === 6) {
                    data.push(timeslot);
                }
            });
        }
        return data;
    },
    mainGetTimeslotsExit: (state) => {
        const items = state.main_items;
        let data = []
        if (items === null) {
            return data
        } else {
            items.data.forEach((timeslot, index) => {
                if (timeslot.loading_status === 7) {
                    data.push(timeslot);
                }
            });
        }
        return data;
    },
    getMainOwnTimeslots: (state) => {
        if (state.main_items !== null) {
            return state.main_items.data_user;
        }
        return null;
    },
    getMainEnqueueItems: (state) => {
        if (state.main_enqueue_items !== null) {
            return state.main_enqueue_items;
        }
        return null;
    },
    getMainRefreshed: (state) => {
        if (state.main_refreshed !== null) {
            return state.main_refreshed;
        }
        return false;
    },
    getMainDateFrom: (state) => state.main_date_from,
    getMainDateTo: (state) => state.main_date_to,
    getMainForceReload: (state) => {
        if (state.main_force_reload !== null) {
            return state.main_force_reload;
        }
        return false;
    },
    getMainItemId: (state) => {
        return state.main_item_id;
    },
    getMainItem: (state) => {
        return state.main_item;
    },
    main_get_item_loading_status_changeable: (state) => {
        return state.main_item_chg_loading_status;
    },
    getMainItemChangeable: (state) => {
        return state.main_item_chg_item;
    },
    getMainFilterLoadingStatus(state) {
        return state.main_filter_loading_status;
    },
    getMainFilterSort(state) {
        return state.main_filter_sort;
    },
    getMainFilterLoadingArea(state) {
        return state.main_filter_loading_area;
    },
    getMainFilterLoadingType(state) {
        return state.main_filter_loading_type;
    },
    getMainPart2(state) {
        return state.main_part_2;
    },
    main_get_loading_type_config: (state) => state.main_part_2,
    main_get_loading_checked(state) {
        return state.main_item.confirm_2;
    },
    main_get_is_today(state) {
        return state.main_is_today;
    },
    main_get_add_days(state) {
        return state.main_add_days;
    },
}

const mainModule = {
    state,
    mutations,
    actions,
    getters,
}

export default mainModule
