<script setup>
import {computed, reactive} from 'vue'
import {RouterView} from 'vue-router'
import {useHead} from '@vueuse/head'
import {useStore} from "vuex";

const store = useStore()
const siteData = reactive({
  title: process.env.VUE_APP_TITLE,
  description: `TSM-System`,
})
useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: `description`,
      content: computed(() => siteData.description),
    },
  ],
})
onbeforeunload = () => {
  store.dispatch("signout")
};
</script>

<template>
  <router-view></router-view>
</template>


<style>

</style>
