export default function useTsmDateTimeString(date_obj, date_format, locales) {
    /*
    date_format = "tsm-date" -> Format: yymmdd
                  "date" -> Format: dd.mm.yyyy
     */

    /*
        function getDateObj(cfg_date_arr) {
            const tsm_date_value = new Date(Date.UTC(cfg_date_arr["year"], cfg_date_arr["month"], cfg_date_arr["day"],
                cfg_date_arr["hours"], cfg_date_arr["minutes"], cfg_date_arr["seconds"]));
            return tsm_date_value;
        }

        function conIntoString(con_value) {
            if (typeof con_value == 'string') {
                return con_value;
            }
            if (typeof con_value == 'number') {
                return con_value.toString();
            }
            return null;
        }

        function getArrValue(arr_value, arr_pos) {
            if (arr_value.length < arr_pos + 1) {
                return 0;
            }
            return parseInt(arr_value[arr_pos]);
        }

        function conIntoDateTimeArr(str_value) {
            const str_arr = str_value.split("", 2);
            const date_arr = {
                "year": getArrValue(str_arr, 0),
                "month": getArrValue(str_arr, 1),
                "day": getArrValue(str_arr, 2),
                "hours": getArrValue(str_arr, 3),
                "minutes": getArrValue(str_arr, 4),
                "seconds": getArrValue(str_arr, 5),
            }
            return date_arr;
        }

     */

    function toDateString(dateObj) {
        const arrDateTime = dateObj.toLocaleDateString("en-US", {
            year: "2-digit", month: "2-digit", day: "2-digit",
            hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit",
            weekday: 'long',
        }).split(", ");
        //console.log(arrDateTime);
        const arrDate = arrDateTime[1].split("/");
        return arrDate[2] + arrDate[0] + arrDate[1];
    }

    function genFormatedDateString(dateObj, locales) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric'};
        return dateObj.toLocaleDateString(locales, options)
    }

    /*
    const tsm_date_string = conIntoString(date_time);
    let date_obj;
    if (tsm_date_string === "0") {
        date_obj = new Date();
    } else {
        date_obj = getDateObj(conIntoDateTimeArr(tsm_date_string));
    }
     */
    //console.log("selected tsmDate: " + toDateString(date_obj));
    //console.log("tsmDate wurde mit Test tsmDate 221214 überschrieben in TsmDateTimeString");
    //console.log(date_format);
    if (date_format.localeCompare("tsm-date") === 0) {
        // console.log("date-format tsm-date");
        if (date_obj instanceof Date) {
            return toDateString(date_obj);
        }
        return null
    }
    if (date_format.localeCompare("date") === 0) {
        //console.log("date-format");
        return genFormatedDateString(date_obj, locales);
    }
    if (date_format.localeCompare("compare") === 0) {
        const current_day = toDateString(new Date());
        const selected_day = toDateString(date_obj);
        const compare_value = selected_day.localeCompare(current_day)
        if (compare_value === 0) {
            return "today"
        } else if (compare_value === 1) {
            return "after"
        } else {
            return "before"
        }
    }
    console.log("date-format not found");
    return date_obj;
    //return "221214";
}
