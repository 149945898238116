export default {
  "default_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "default_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "No authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No authorization"])},
  "btn_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Save and Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Close"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Back to list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to list"])},
  "Back to home page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Page not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
  "Sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "User profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User profile"])},
  "Customer authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer authorization"])},
  "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "Loading area delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading area delivery"])},
  "Loading area pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading area pickup"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "Staff ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff ID"])},
  "Staff options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff options"])},
  "Staff function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff function"])},
  "License plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate"])},
  "Filter in timeslot overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter in timeslot overview"])},
  "Edit timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit timeslot"])},
  "Timeslot is locked by another user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeslot is locked by another user"])},
  "Lp Truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate truck"])},
  "License plate trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate trailer"])},
  "Lp Trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lp Trailer"])},
  "vt-truck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
  "vt-tractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truck"])},
  "vt-semitrailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semitrailer"])},
  "vt-trailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailer"])},
  "fassware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fassware"])},
  "short_fassware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fass"])},
  "short_code_xl_getraenke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code XL-G"])},
  "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "txt_loading_layer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einlagig"])},
  "txt_short_loading_layer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein"])},
  "txt_loading_layer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zweilagig"])},
  "txt_short_loading_layer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zwei"])},
  "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valid"])},
  "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalid"])},
  "Release Timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Timeslot"])},
  "Take over timeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take over timeslot"])},
  "Timeslots assigned to me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeslots assigned to me"])},
  "All booked timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All booked timeslots"])},
  "Loading status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading status"])},
  "Reset loading status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset loading status"])},
  "Check load securing before exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check load securing before exit"])},
  "Click here if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here if"])},
  "Load securing checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load securing checked"])},
  "Reset load securing check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset load securing check"])},
  "Select loading location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select loading location"])},
  "Select default for employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select default for employee"])},
  "Loading Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Area"])},
  "Type of loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of loading"])},
  "Loading area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading area"])},
  "url_imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.logipack.com/impressum"])},
  "url_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.logipack.com/datenschutz"])},
  "9030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ West"])},
  "9031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Südwest"])},
  "9032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Nord"])},
  "9033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logipack LVZ Ost"])},
  "txt_lkwart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugart"])},
  "9030_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9030_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ West - Hamm"])},
  "9031_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9031_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Südwest"])},
  "9032_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9032_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Nord"])},
  "9033_txt_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIPACK"])},
  "9033_txt_logo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Ost"])},
  "0030i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ West - Hamm"])},
  "0031i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Südwest"])},
  "0032i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Nord"])},
  "0033i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Ost"])},
  "1033i_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LVZ - Ost"])},
  "0030i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0031i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0032i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0033i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "1033i_txt_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer Fahrer"])},
  "0030i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0031i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0032i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0033i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "1033i_txt_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung von"])},
  "0030i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0031i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0032i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0033i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "1033i_txt_info3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung für"])},
  "0030i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0031i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0032i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0033i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "1033i_txt_info4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgang LVZ"])},
  "0030i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0031i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0032i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0033i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "1033i_txt_value_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0030i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0031i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0032i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0033i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "1033i_txt_value_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler"])},
  "0030i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0031i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0032i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0033i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "1033i_txt_value_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stapler Fahrer"])},
  "0030i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0031i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0032i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0033i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "1033i_txt_value_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLB-Prüfung"])},
  "0030i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0031i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0032i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0033i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "1033i_txt_lkw1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Fahrzeug seitlich zu beladen oder mit Hebebühne"])},
  "0030i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0031i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0032i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0033i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "1033i_txt_lkw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautliner / Auflieger"])},
  "0030i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0031i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0032i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0033i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "1033i_txt_lkw3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränkekoffer /Schwenkwandaufbau -seitlich zu beladen"])},
  "0030i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0031i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0032i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0033i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "1033i_txt_lkw4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megaliner / Jumbo"])},
  "0030i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0031i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0032i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0033i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "1033i_txt_lkw5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporter"])},
  "0030i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0031i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0032i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0033i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "1033i_txt_lkw6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pkw"])},
  "0030i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0031i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0032i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0033i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "1033i_txt_eg0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Anlieferung"])},
  "0030i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0031i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0032i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0033i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "1033i_txt_eg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Abholung"])},
  "0030i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0031i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0032i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0033i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "1033i_txt_eg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlieferung und Abholung"])},
  "0030i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg3"])},
  "0031i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0031i_txt_eg3"])},
  "0032i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0032i_txt_eg3"])},
  "0033i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0033i_txt_eg3"])},
  "1033i_txt_eg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1033i_txt_eg3"])},
  "0030i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0031i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0032i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0033i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "1033i_txt_eg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0030i_txt_eg4"])},
  "0030i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0031i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0032i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0033i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "1033i_txt_leergut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung"])},
  "0030i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0031i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0032i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "0033i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "1033i_txt_verladeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang LVZ"])},
  "9030_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9031_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9032_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9033_txt_loading_status_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin gebucht"])},
  "9030_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9031_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9032_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9033_txt_loading_status_next_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert setzen"])},
  "9030_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9031_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9032_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9033_txt_loading_status_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert"])},
  "9030_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9031_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9032_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9033_txt_loading_status_next_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung starten"])},
  "9030_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9031_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9032_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9033_txt_loading_status_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Start"])},
  "9030_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9031_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9032_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9033_txt_loading_status_next_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden"])},
  "9030_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9031_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9032_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9033_txt_loading_status_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9030_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9031_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9032_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9033_txt_loading_status_next_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beenden3"])},
  "9030_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9031_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9032_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9033_txt_loading_status_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriert4"])},
  "9030_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9031_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9032_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9033_txt_loading_status_next_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beladung starten"])},
  "9030_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_next_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9031_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9032_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9033_txt_loading_status_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung Ende"])},
  "9030_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9031_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9032_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9033_txt_loading_status_next_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladung beendet"])},
  "9030_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9031_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9032_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9033_txt_loading_status_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof verlassen"])},
  "9030_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9031_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9032_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "9033_txt_loading_status_next_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiten zürcksetzen"])},
  "0030i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 2"])},
  "0030i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor 19"])},
  "0030i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor Intern"])},
  "0031i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spur 1"])},
  "0031i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spur 2"])},
  "0031i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweich Spur"])},
  "0032i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 1"])},
  "0032i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweich Spur"])},
  "0033i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 1"])},
  "0033i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 2"])},
  "0033i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leergut 3"])},
  "0033i_lane_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LG Ausweich 1"])},
  "0033i_lane_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LG Ausweich 2"])},
  "1033i_lane_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 1"])},
  "1033i_lane_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 2"])},
  "1033i_lane_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollgut 3"])},
  "1033i_lane_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VG Ausweich"])},
  "1033i_lane_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1033i_lane_5"])},
  "page_contact_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your contact person for inquiries<br>", _interpolate(_named("SUPPORT_MAIL"))])},
  "Your account has been locked due to too many login attempts.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been locked due to too many login attempts."])},
  "Email or password incorrect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or password incorrect."])},
  "User is locked please contact support.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUser is locked please contact support."])},
  "An unknown error has occurred. Please contact the support.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred. Please contact the support."])},
  "Please contact support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact support."])},
  "No authorization for the internal area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No authorization for the internal area."])},
  "Please contact administration.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact administration."])},
  "No timeslots found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No timeslots found"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "Polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])}
}